"use client";

import React, { useEffect, useState } from "react";
import { FaAngleRight } from "react-icons/fa6";
import { FcShop } from "react-icons/fc";
import http from "@/lib/http";
import { IBusiness, IBusinessUpdate } from "@/types/user.type";
import { Skeleton, useToast } from "@chakra-ui/react";
import classNames from "classnames";
import { ImSpinner8 } from "react-icons/im";
import { handleResponseError } from "@/utils/errors";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { useTheme } from "@/contexts/themeProvider";
import { useNavigate } from "react-router-dom";
import { useApp } from "@/contexts/appContext";

function BusinessAccountDetailsClient() {
  const navigate = useNavigate();
  const { user } = useApp();

  const [business, setBusiness] = useState<IBusiness | null>(null);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { resolvedTheme } = useTheme();

  const [isFetching, setIsFetching] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [tab, setTab] = useState<"details" | "update">("details");
  const [loading, setLoading] = useState(false);
  // const [isFetchingStates, setIsFetchingStates] = useState(true);
  // const [isFetchingLgas, setIsFetchingLgas] = useState(false);
  // const [businessCategories, setBusinessCategories] = useState<{
  //     "business_category_id": string,
  //     "business_category": string
  // }[]>([]);
  // const [states, setStates] = useState<{
  //     "state_id": string,
  //     "state_name": string
  // }[]>([]);
  // const [lgas, setLgas] = useState<{
  //     "lga_id": string,
  //     "state_id": string,
  //     "country_code": string,
  //     "lga_name": string
  // }[]>([]);

  const [updateBusiness, setUpdateBusiness] = useState<IBusinessUpdate>({
    otp: "",
    reference_code: "",
    email: "",
    phone: "",
    short_name: "",
    logo: "",
    login: "",
    login_2fa: "",
    registration: "",
    ip_address: "",
    webhook_url: "",
    logged_out_users: "",
    user_registration_invitation_required: "",
  });

  async function fetchBusiness() {
    try {
      const resp = await http<IBusiness>("settings/details");
      console.log("business", resp);
      const b_: IBusiness = resp.data;
      setBusiness(b_);
      setUpdateBusiness({
        otp: "",
        reference_code: "",
        email: b_.email,
        phone: b_.phone,
        short_name: b_.short_name,

        logo: b_.logo || "",

        login: b_.security.find((e) => e.type === "login")?.value || "",
        login_2fa: b_.security.find((e) => e.type === "login_2fa")?.value || "",
        registration:
          b_.security.find((e) => e.type === "registration")?.value || "",
        ip_address:
          b_.security.find((e) => e.type === "ip_address")?.value || "",
        webhook_url:
          b_.security.find((e) => e.type === "webhook_url")?.value || "",
        logged_out_users:
          b_.security.find((e) => e.type === "logged_out_users")?.value || "",
        user_registration_invitation_required:
          b_.security.find(
            (e) => e.type === "user_registration_invitation_required"
          )?.value || "",
      });

      setIsFetching(false);
    } catch (error) {
      console.log(error);
      // setIsFetching(false);
    }
  }

  useEffect(() => {
    fetchBusiness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // async function getStates() {
  //     try {
  //         const resp = await httpRaw("https://dev.lucrestack.co/misc/state", { country_code: "NG" }, process.env.REACT_APP_PUBLIC_KEY);
  //         // console.log("states", resp.data)
  //         setStates(resp.data)
  //         // setIsFetchingStates(false);
  //     } catch (error) {
  //         console.log(error)
  //         // setIsFetchingStates(false);
  //         handleResponseError(error, toast);
  //     }
  // }

  // async function getBusinessCategories() {
  //     try {
  //         const resp = await httpRaw("https://dev.lucrestack.co/misc/business-category", {}, process.env.REACT_APP_PUBLIC_KEY);
  //         // console.log("states", resp.data)
  //         setBusinessCategories(resp.data)
  //         // setIsFetchingStates(false);
  //     } catch (error) {
  //         console.log(error)
  //         // setIsFetchingStates(false);
  //         handleResponseError(error, toast);
  //     }
  // }

  // async function getLgas() {
  //     // setIsFetchingLgas(true);
  //     setLgas([]);
  //     try {
  //         const resp = await httpRaw("https://dev.lucrestack.co/misc/lga", { state_id: updateBusiness.state_id }, process.env.REACT_APP_PUBLIC_KEY);
  //         // console.log("lgas", resp.data)
  //         setLgas(resp.data)
  //         // setIsFetchingLgas(false);
  //     } catch (error) {
  //         console.log(error)
  //         // setIsFetchingLgas(false);
  //         handleResponseError(error, toast);
  //     }
  // }

  // useEffect(() => {
  //     getStates();
  //     getBusinessCategories();
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  // useEffect(() => {
  //     console.log("state_id", updateBusiness.state_id)
  //     if (updateBusiness.state_id.length > 0) {
  //         getLgas();
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [updateBusiness.state_id])

  async function updateAccountInitiate() {
    setIsSending(true);
    try {
      const resp = await http("settings/initiate", {
        // email: updateBusiness.email,
        email: user?.email,
      });
      console.log("update initiate resp", resp.data);
      setUpdateBusiness((b) => ({
        ...b,
        reference_code: resp.data.reference_code,
      }));
      toast({
        title: "Success",
        description: resp.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onOpen();
    } catch (error) {
      console.log(error);
      setIsSending(false);
      handleResponseError(error, toast);
    }
  }

  async function updateAccountFinalize() {
    setLoading(true);
    try {
      const resp = await http("settings/finalise", { ...updateBusiness });
      console.log("update finalise resp", resp.data);
      toast({
        title: "Success",
        description: resp.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setLoading(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
      setLoading(false);
      handleResponseError(error, toast);
    }
  }

  function handleClose() {
    setUpdateBusiness((b) => ({ ...b, reference_code: "" }));
    onClose();
  }

  function getSecurityData(type: string) {
    if (type.length < 1) return "";
    if (business) {
      return business.security.find((e) => e.type === type)?.value || "";
    } else {
      return "";
    }
  }

  return (
    <>
      <div className="max-w-full mb-10 overflow-auto md:max-w-full">
        <div className="flex items-center gap-3 text-xs text-slate-500">
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate("/d/overview");
            }}
          >
            Business
          </div>
          <div>
            <FaAngleRight />
          </div>
          {/* <div>Account</div>
                    <div><FaAngleRight /></div> */}
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate("/d/business/details");
            }}
          >
            Details
          </div>
        </div>
      </div>

      <div className="mb-8 text-xl">Business Details</div>
      <hr className="mb-8 border-slate-300 dark:border-neutral-700" />

      <div className="w-full mb-10">
        <div className="flex items-center justify-between mb-10">
          <div className="flex items-center gap-3">
            <div className="p-4 bg-purple-800 rounded-full">
              <FcShop className="w-7 h-7" />
            </div>
            <div className="text-sm font-bold">
              {business ? (
                <span>{business.name}</span>
              ) : (
                <Skeleton w={"100px"} h={"20px"} />
              )}
            </div>
          </div>
        </div>

        <div className="flex gap-5 mb-8">
          <button
            className={classNames("text-[13px]", {
              "text-purple-600 underline font-bold": tab === "details",
              "": tab === "update",
            })}
            onClick={() => {
              setTab("details");
            }}
          >
            Details
          </button>
          <button
            className={classNames("text-[13px]", {
              "text-purple-600 underline font-bold": tab === "update",
              "": tab === "update",
            })}
            onClick={() => {
              setTab("update");
            }}
          >
            Update
          </button>
        </div>

        {tab === "details" ? (
          <>
            <div className="mb-14">
              <div className="inline-block border-b-2 border-purple-800 pb-[3px] mb-3 text-sm font-bold">
                Business information
              </div>

              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
                <div>
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500">
                    Name
                  </div>
                  <div className="text-sm">
                    {business ? (
                      <span>{business.name}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500">
                    Short Name
                  </div>
                  <div className="text-sm">
                    {business ? (
                      <span>
                        {business.short_name?.trim()?.length > 0
                          ? business.short_name
                          : "-"}
                      </span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500">
                    Email
                  </div>
                  <div className="text-sm">
                    {business ? (
                      <span>{business.email}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500">
                    Phone
                  </div>
                  <div className="text-sm">
                    {business ? (
                      <span>{business.phone}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500">
                    Country Code
                  </div>
                  <div className="text-sm">
                    {business ? (
                      <span>{business.country_code}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500">
                    Business Category
                  </div>
                  <div className="text-sm">
                    {business ? (
                      <span>{business.business_category}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500">
                    Status
                  </div>
                  <div className="text-sm">
                    {business ? (
                      <span>{business.status}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500">
                    Subscription Status
                  </div>
                  <div className="text-sm">
                    {business ? (
                      <span>{business.subscription_status}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500">
                    Subscription Timestamp
                  </div>
                  <div className="text-sm">
                    {business ? (
                      <span>{business.subscription_timestamp}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500">
                    Active
                  </div>
                  <div className="text-sm">
                    {business ? (
                      <span>{business.active}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500">
                    Created On
                  </div>
                  <div className="text-sm">
                    {business ? (
                      <span>{business.created_on}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-14">
              <div className="inline-block border-b-2 border-purple-800 pb-[3px] mb-3 text-sm font-bold">
                Security
              </div>

              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
                <div>
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500">
                    Public key
                  </div>
                  <div className="text-sm">
                    {business ? (
                      <span>{getSecurityData("public_key")}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500">
                    Login
                  </div>
                  <div className="text-sm">
                    {business ? (
                      <span className="capitalize">
                        {getSecurityData("login")}
                      </span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500">
                    Registration
                  </div>
                  <div className="text-sm">
                    {business ? (
                      <span className="capitalize">
                        {getSecurityData("registration")}
                      </span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500">
                    IP address
                  </div>
                  <div className="text-sm">
                    {business ? (
                      <span>
                        {getSecurityData("ip_address")?.trim().length > 0
                          ? getSecurityData("ip_address")
                          : "-"}
                      </span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500">
                    Login 2fa
                  </div>
                  <div className="text-sm">
                    {business ? (
                      <span className="capitalize">
                        {getSecurityData("login_2fa")}
                      </span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500">
                    Webhook url
                  </div>
                  <div className="text-sm">
                    {business ? (
                      <span>
                        {getSecurityData("webhook_url").trim().length > 0
                          ? getSecurityData("webhook_url")
                          : "-"}
                      </span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>

                <div>
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500">
                    System Authentication Requirement
                  </div>
                  <div className="text-sm">
                    {business ? (
                      <span>
                        {getSecurityData(
                          "user_registration_invitation_required"
                        ).trim().length > 0
                          ? getSecurityData(
                              "user_registration_invitation_required"
                            )
                          : "-"}
                      </span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-10">
              <div className="inline-block border-b-2 border-purple-800 pb-[3px] mb-3 text-sm font-bold">
                Location
              </div>

              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
                <div>
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500">
                    Address
                  </div>
                  <div className="text-sm">
                    {business ? (
                      <span>{business.address}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500">
                    State
                  </div>
                  <div className="text-sm">
                    {business ? (
                      <span>{business.state}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500">
                    City
                  </div>
                  <div className="text-sm">
                    {business ? (
                      <span>{business.city}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500">
                    Country
                  </div>
                  <div className="text-sm">
                    {business ? (
                      <span>{business.country}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className='mb-10'>
                            <div className='inline-block border-b-2 border-purple-800 pb-[3px] mb-3 text-sm font-bold'>Account officer</div>

                            <div className='grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4'>
                                <div>
                                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500'>firstname</div>
                                    <div className='text-sm'>{business ? <span>{business.account_officer.firstname}</span> : <Skeleton w={'100px'} h={'20px'} />}</div>
                                </div>
                                <div>
                                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500'>lastname</div>
                                    <div className='text-sm'>{business ? <span>{business.account_officer.lastname}</span> : <Skeleton w={'100px'} h={'20px'} />}</div>
                                </div>
                                <div>
                                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500'>middlename</div>
                                    <div className='text-sm'>{business ? <span>{business.account_officer.middlename}</span> : <Skeleton w={'100px'} h={'20px'} />}</div>
                                </div>
                            </div>
                        </div> */}
          </>
        ) : (
          <>
            <div className="mb-8">
              <div className="inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold">
                Business Info{" "}
                {isFetching && (
                  <ImSpinner8 className="inline-block w-4 h-4 ml-3 animate-spin" />
                )}
              </div>
              <div className="grid grid-cols-1 gap-5 mb-5 md:grid-cols-2 lg:grid-cols-4">
                <div className="">
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                    Email
                  </div>
                  <input
                    type="text"
                    value={updateBusiness.email}
                    className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                    onChange={(e) =>
                      setUpdateBusiness((b) => ({
                        ...b,
                        email: e.target.value,
                      }))
                    }
                    placeholder=""
                    disabled={isSending}
                  />
                </div>
                <div className="">
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                    Short name
                  </div>
                  <input
                    type="text"
                    value={updateBusiness.short_name}
                    className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                    onChange={(e) =>
                      setUpdateBusiness((b) => ({
                        ...b,
                        short_name: e.target.value,
                      }))
                    }
                    placeholder=""
                    disabled={isSending}
                  />
                </div>
                <div className="">
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                    Phone
                  </div>
                  <input
                    type="text"
                    value={updateBusiness.phone}
                    className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                    onChange={(e) =>
                      setUpdateBusiness((b) => ({
                        ...b,
                        phone: e.target.value,
                      }))
                    }
                    placeholder=""
                    disabled={isSending}
                  />
                </div>
                {/* <div className=''>
                                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Category</div>
                                    <select value={updateBusiness.business_category_id} className='w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700' onChange={(e) => setUpdateBusiness((b) => ({ ...b, business_category_id: e.target.value }))} disabled={isSending}>
                                        <option value="">Select</option>
                                        {businessCategories.map((e, i) => (
                                            <option key={i} value={e.business_category_id}>{e.business_category}</option>
                                        ))}
                                    </select>
                                </div> */}
              </div>

              <div className="inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold">
                Security
              </div>
              <div className="grid grid-cols-1 gap-5 mb-5 md:grid-cols-2 lg:grid-cols-4">
                <div className="">
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                    Login
                  </div>
                  <select
                    value={updateBusiness.login}
                    className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                    onChange={(e) =>
                      setUpdateBusiness((b) => ({
                        ...b,
                        login: e.target.value,
                      }))
                    }
                    disabled={isSending}
                  >
                    <option value="allowed">Allowed</option>
                    <option value="disallowed">Disallowed</option>
                  </select>
                </div>

                <div className="">
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                    Registration
                  </div>
                  <select
                    value={updateBusiness.registration}
                    className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                    onChange={(e) =>
                      setUpdateBusiness((b) => ({
                        ...b,
                        registration: e.target.value,
                      }))
                    }
                    disabled={isSending}
                  >
                    <option value="allowed">Allowed</option>
                    <option value="disallowed">Disallowed</option>
                  </select>
                </div>

                <div className="">
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                    Login 2fa
                  </div>
                  <select
                    value={updateBusiness.login_2fa}
                    className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                    onChange={(e) =>
                      setUpdateBusiness((b) => ({
                        ...b,
                        login_2fa: e.target.value,
                      }))
                    }
                    disabled={isSending}
                  >
                    <option value="">Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>

                <div className="">
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                    Logged out users
                  </div>
                  <select
                    value={updateBusiness.logged_out_users}
                    className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                    onChange={(e) =>
                      setUpdateBusiness((b) => ({
                        ...b,
                        logged_out_users: e.target.value,
                      }))
                    }
                    disabled={isSending}
                  >
                    <option value="">Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>

                <div className="">
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                    IP address
                  </div>
                  <input
                    type="text"
                    value={updateBusiness.ip_address}
                    className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                    onChange={(e) =>
                      setUpdateBusiness((b) => ({
                        ...b,
                        ip_address: e.target.value,
                      }))
                    }
                    placeholder=""
                    disabled={isSending}
                  />
                </div>

                <div className="">
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                    Webhook url
                  </div>
                  <input
                    type="text"
                    value={updateBusiness.webhook_url}
                    className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                    onChange={(e) =>
                      setUpdateBusiness((b) => ({
                        ...b,
                        webhook_url: e.target.value,
                      }))
                    }
                    placeholder=""
                    disabled={isSending}
                  />
                </div>

                <div className="">
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                    System Authentication Requirement
                  </div>
                  <select
                    value={updateBusiness.user_registration_invitation_required}
                    className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                    onChange={(e) =>
                      setUpdateBusiness((b) => ({
                        ...b,
                        user_registration_invitation_required: e.target.value,
                      }))
                    }
                    disabled={isSending}
                  >
                    <option value="">Select</option>
                    <option value="None">None</option>
                    <option value="System">System</option>
                    <option value="System_User">System User</option>
                    <option value="Any_System_User">Any System User</option>
                  </select>
                </div>
              </div>

              <div>
                {/* <div className='inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold'>Primary contact</div>
                            <div className='grid grid-cols-1 gap-5 mb-5 md:grid-cols-2 lg:grid-cols-4'>
                                <div className=''>
                                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Email</div>
                                    <input type="text" value={updateBusiness.primary_contact.email} className='w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700' onChange={(e) => setUpdateBusiness((b) => ({ ...b, primary_contact: {...b.primary_contact, email: e.target.value} }))} placeholder='' disabled={isSending} />
                                </div>
                                <div className=''>
                                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Phone</div>
                                    <input type="text" value={updateBusiness.primary_contact.phone} className='w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700' onChange={(e) => setUpdateBusiness((b) => ({ ...b, primary_contact: {...b.primary_contact, phone: e.target.value} }))} placeholder='' disabled={isSending} />
                                </div>
                                <div className=''>
                                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Firstname</div>
                                    <input type="text" value={updateBusiness.primary_contact.firstname} className='w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700' onChange={(e) => setUpdateBusiness((b) => ({ ...b, primary_contact: {...b.primary_contact, firstname: e.target.value} }))} placeholder='' disabled={isSending} />
                                </div>
                                <div className=''>
                                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Lastname</div>
                                    <input type="text" value={updateBusiness.primary_contact.lastname} className='w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700' onChange={(e) => setUpdateBusiness((b) => ({ ...b, primary_contact: {...b.primary_contact, lastname: e.target.value} }))} placeholder='' disabled={isSending} />
                                </div>
                            </div> */}

                {/* <div className='inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold'>Location</div>
                            <div className='grid grid-cols-1 gap-5 mb-10 md:grid-cols-2 lg:grid-cols-4'>
                                <div className=''>
                                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Address</div>
                                    <input type="text" value={updateBusiness.address} className='w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700' onChange={(e) => setUpdateBusiness((b) => ({ ...b, address: e.target.value }))} placeholder='' disabled={isSending} />
                                </div>
                                <div className=''>
                                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>State</div>
                                    <select value={updateBusiness.state_id} className='w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700' onChange={(e) => setUpdateBusiness((b) => ({ ...b, state_id: e.target.value }))} disabled={isSending}>
                                        <option value="">Select</option>
                                        {states.map((e, i) => (
                                            <option key={i} value={e.state_id}>{e.state_name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className=''>
                                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>City</div>
                                    <input type="text" value={updateBusiness.city} className='w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700' onChange={(e) => setUpdateBusiness((b) => ({ ...b, city: e.target.value }))} placeholder='' disabled={isSending} />
                                </div>
                                <div className=''>
                                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>LGA</div>
                                    <select value={updateBusiness.city} className='w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700' onChange={(e) => setUpdateBusiness((b) => ({ ...b, lga_id: e.target.value }))} disabled={isSending}>
                                        <option value="">Select</option>
                                        {lgas.map((e, i) => (
                                            <option key={i} value={e.lga_id}>{e.lga_name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div> */}
              </div>

              <div>
                <button
                  className="flex justify-center px-10 py-2 text-sm bg-purple-800 text-neutral-100 disabled:bg-opacity-50"
                  onClick={() => updateAccountInitiate()}
                  disabled={isSending || loading}
                >
                  {isSending ? (
                    <ImSpinner8 className="w-5 h-5 animate-spin" />
                  ) : (
                    <span>Submit</span>
                  )}
                </button>
              </div>
            </div>
          </>
        )}
      </div>

      <Modal
        closeOnOverlayClick={false}
        onClose={handleClose}
        isOpen={isOpen}
        size={{ base: "sm", md: "lg" }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            className={classNames("", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          >
            Update Business
          </ModalHeader>
          <ModalCloseButton
            className={classNames("outline-none", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          />

          <ModalBody
            className={classNames("", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          >
            <div className="mb-3 font-bold">
              A one time password was sent to {user?.email}
            </div>

            <div className="mb-3">
              {/* <div className='inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold'>{currencyFormat(data.available_balance)}</div> */}
              <div className="mb-5">
                <div className="">
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                    OTP
                  </div>
                  <input
                    type="text"
                    value={updateBusiness.otp}
                    className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                    onChange={(e) =>
                      setUpdateBusiness((b) => ({ ...b, otp: e.target.value }))
                    }
                    placeholder="OTP"
                    // disabled={isSending}
                  />
                </div>
              </div>

              <button
                className="flex justify-center px-10 py-2 text-sm bg-purple-800 text-neutral-100 disabled:bg-opacity-50"
                onClick={() => updateAccountFinalize()}
                disabled={loading || updateBusiness.otp.length === 0}
              >
                {loading ? (
                  <ImSpinner8 className="w-5 h-5 animate-spin" />
                ) : (
                  <span>Submit</span>
                )}
              </button>
            </div>
          </ModalBody>

          <ModalFooter
            className={classNames("", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          >
            <button onClick={onClose}>Close</button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default BusinessAccountDetailsClient;
